import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios';
Vue.use(Vuex)

Vue.prototype.$auth = {}
Vue.prototype.$http = axios
Vue.prototype.$auth.user = (() => {
	return JSON.parse( window.localStorage.getItem('oml-user') )
});

// Set Vue authentication
Vue.use(VueAxios, axios)
/* axios.defaults.baseURL = `${process.env.MIX_APP_URL}/api` */
/* axios.defaults.baseURL = `/api` */
axios.defaults.baseURL = process.env.VUE_APP_API_URL
// axios.defaults.baseURL = `http://127.0.0.1:8000/api`
// Vue.use(/* VueAuth, */ auth)
// Load Index

axios.defaults.headers.common = {
	'Accept': 'application/json',
	'Authorization': 'Bearer ' + window.localStorage.getItem('oml-token')
};

const cancelTokens = {};

const store = new Vuex.Store({
	state: {
		status	: '',
		userToken: localStorage.getItem('oml-token') || '',
		user 	: JSON.parse( localStorage.getItem('oml-user') ) || {},
		total  :{
			leadsCountSide	: 0,
			sourcesCount	: 0,
		},
		response : {
			success : false
		},
		selectedPage: '',
		
		planInterval: 'monthly',
		
		CompanyName	: '',
		DefaultLogo	: '',
		WhiteLabel	: false,
		IsAdmin		: false,
		
		WorkSpaces		: [],
		SelectedClient	: [],
		
		BlackFriday		: false,
		Permissions : [],
		active : false,
		calendly_url : localStorage.getItem('calendly_url') || '',
		
		//smartviews
		reloadCompenent:1,
		checkSmartVienwsFilter : false,
		clickSmartView : false,
		clickFilter : false,
		
		reloadLeftSidebarCompenent:1,
		checkChange : false,
		
		smartViewsItem : {},
	},
	mutations: {
		auth_request(state){
			state.status = 'loading'
		},
		auth_success(state, token, user) {
			state.status 	= 'success'
			state.userToken	= token
			state.user	 	= user
		},
		auth_error(state) {
			state.status = 'error'
		},
		logout(state) {
			state.status = ''
			state.userToken = ''
		},
	},
	actions: {
		LoginFb({commit}, email) {
			return new Promise((resolve, reject) => {
				commit('auth_request')
				// axios({url: 'http://127.0.0.1:8000/api' + '/auth/login', data: user, method: 'POST' })
				axios.post( '/ohmylead_login', email )
				.then(resp => {
					/* console.log(resp.data) */
					const token = resp.data.access_token
					const user = resp.data.user
					/* localStorage.setItem('oml-token', token)
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token */
					commit('auth_success', token, user)
					resolve(resp)
				}).catch(err => {
					commit('auth_error')
					localStorage.clear()
					reject(err)
				})
			})
		},
		LoginFbBtn({commit}, dataFb) {
			return new Promise((resolve, reject) => {
				commit('auth_request')
				// axios({url: 'http://127.0.0.1:8000/api' + '/auth/login', data: user, method: 'POST' })
				axios.post( '/fb_login', dataFb )
				.then(resp => {
					/* console.log(resp.data) */
					const token = resp.data.access_token
					const user = resp.data.user
					/* localStorage.setItem('oml-token', token)
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token */
					commit('auth_success', token, user)
					resolve(resp)
				}).catch(err => {
					commit('auth_error')
					localStorage.clear()
					reject(err)
				})
			})
		},
		
		PasswordGen({commit}, Obj) {
			return new Promise((resolve, reject) => {
				
				commit('auth_request')
				axios.post( '/auth/generatePassword', Obj ).then(resp => {
					resolve(resp)
				}).catch(err => {
					reject(err)
				})
			})
		},
		
		login({commit}, user) {
			return new Promise((resolve, reject) => {
				commit('auth_request')
				// axios({url: 'http://127.0.0.1:8000/api' + '/auth/login', data: user, method: 'POST' })
				axios.post( '/auth/login', user )
				.then(resp => {
					/* console.log(resp.data) */
					const token = resp.data.access_token
					const user = resp.data.user
					/* localStorage.setItem('oml-token', token)
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token */
					commit('auth_success', token, user)
					resolve(resp)
				}).catch(err => {
					commit('auth_error')
					localStorage.clear()
					reject(err)
				})
			})
		},
		
		register({ commit }, user) {
			return new Promise((resolve, reject) => {
				commit('auth_request')
				// axios({ url: 'http://localhost:3000/register', data: user, method: 'POST' })
				axios.post( '/auth/register', user )
				.then(resp => {
					// const token = resp.data.access_token
					// const user = resp.data.user
					/* localStorage.setItem('oml-token', token) */
					
					// Add the following line:
					/* axios.defaults.headers.common['Authorization'] = 'Bearer ' + token */
					// commit('auth_success', token, user)
					resolve(resp)
				}).catch(err => {
					commit('auth_error', err)
					localStorage.clear()
					reject(err)
				})
			})
		},
		appsumo_register({ commit }, user) {
			return new Promise((resolve, reject) => {
				commit('auth_request')
				axios.post( '/auth/appsumo/register',user)
				.then(resp => {
					resolve(resp)
				}).catch(err => {
					commit('auth_error', err)
					localStorage.clear()
					reject(err)
				})
			})
		},
		pitchground_register({ commit }, user) {
			return new Promise((resolve, reject) => {
				commit('auth_request')
				axios.post( '/auth/pitch/register',user)
				.then(resp => {
					resolve(resp)
				}).catch(err => {
					commit('auth_error', err)
					localStorage.clear()
					reject(err)
				})
			})
		},
		//lead_details({ commit }, header) {
			//commit('auth_request')
			//let url 	= header.url
			//let data 	= header.data
			//return new Promise((resolve, reject) => {
				//commit('auth_request')
				//axios.get(url,data)
				//.then(resp => {
					//resolve(resp)
				//}).catch(err => {
					//commit('auth_error', err)
					//localStorage.clear()
					//reject(err)
				//})
			//})
		//},
		async apiRequest({ commit }, header) {
			try {
				let request = {};
				let url = header.url;
				let method = header.method;
				let data = header.data;
					
				if (typeof cancelTokens[url] !== 'undefined') {
					cancelTokens[url].cancel("Operation canceled due to new request.");
					delete cancelTokens[url];
				}
				cancelTokens[url] = axios.CancelToken.source();
				commit('auth_request');
				
				if (method == "GET") {
				request = { url: url, method: method, params: data };
				} else {
				request = { url: url, method: method, data: data, cancelToken: cancelTokens[url].token };
				}
			
				let client = window.localStorage.getItem('oml-client');
				client = JSON.parse(client);
				data.client_id = client.id;
			
				const resp = await axios(request);
			
				if (request.url == 'left_sidebar') {
					this.state.total.leadsCountSide = resp.data.leadsCount;
					this.state.total.sourcesCount = resp.data.sourcesCount;
					this.state.response.success = true;
					return this.state.response;
				} else {
				return resp;
				}
			} catch (err) {
				console.error(err);
			
				if (axios.isCancel(err)) {
				console.log("Request canceled:", err.message);
				} else if (err.response && err.response.status == 403 && err.response.statusText == "Forbidden" && err.response.data.message == "Not allowed to access this client!") {
					let clients = JSON.parse(localStorage.getItem('oml-clients'));
					let client = JSON.parse(localStorage.getItem('oml-client'));
					let newList = [];
					clients.forEach(function (item) {
					if (item.id != client.id) {
						newList.push(item);
					}
					});
					localStorage.setItem('oml-clients', JSON.stringify(newList));
					localStorage.setItem('oml-client', JSON.stringify(clients[0]));
			
					location.reload();
				}
			
				throw err;
			}
		},

		
		clientSession({ commit }) {
			commit('auth_request')
			return new Promise((resolve, reject) => {
				axios({ url		: '/client/getclients', method	: 'POST' }).then((response) => {
					let myTeams = response.data.data.mypersonalteams
					let clients = response.data.data.clients
					let client  = false
					
					clients.forEach( function(cl) {
						if( cl['default'] == cl['id'] ) {
							client = JSON.stringify( cl )
							localStorage.setItem('oml-client' , client )
						}
					} )
					
					if ( client == false ) {
						localStorage.setItem('oml-client' , JSON.stringify( clients[0] ) )
					}
					
					localStorage.setItem('oml-clients', JSON.stringify(clients) )
					localStorage.setItem('user-teams', JSON.stringify(myTeams) )
					// that.$router.push({path: '/mySources/' + source, params: {source: source}})
					resolve(response)
				}, (err) => {
					reject(err)
				})
			})
		},
		/* DoAction() {
			// store.dispatch('DoAction')
			// Vue.prototpe.bvModal.show('modal-black-friday')
			// this.state.BlackFriday = true
			if( localStorage.getItem('action') == 'bl') {
				this.state.BlackFriday = true
				localStorage.removeItem('action')
			}
		}, */
		GetClients() {
			
			store.dispatch('clientSession').then( (response) => {
				let clients  = response.data.data.clients
				let client   = JSON.parse ( localStorage.getItem('oml-client') )
				// that.myTeams = response.data.data.mypersonalteams
				store.state.WorkSpaces = []
				clients.forEach( function(item) {
					if( client.id == item.id ) {
						store.state.SelectedClient = [item.id]
					}
					store.state.WorkSpaces.push({ id: item.id, title: item.name, tag: item.total, enable: item.enable })
				} )
				
			})
			
		},
		logout({commit}){
			return new Promise((resolve , reject) => {
				commit('logout')
				localStorage.clear()
				
				// delete axios.defaults.headers.common['Authorization']
				
				resolve()
				throw reject;
			})
		}
		
	},
	getters : {
		isLoggedIn: state => !!state.userToken,
		authStatus: state => state.status,
	}
})

Vue.prototype.$auth.check 	= ((el = '') => {
	// return window.localStorage.getItem('oml-token') == null ? false : true
	
	if ( el == 2 ) {
		return false
	} else {
		return store.getters.isLoggedIn
	}
	
});

export default store
