import axios from 'axios'

 
const mixin = {
	data: () => ({
		
		LocationData: {host:'', hostname:'', origin:''},
		workspaceTimezone:'',
		workspaceDateFormat:'DD.MM.YYYY HH:mm',
		isWhiteLabel: false,
	}),
	methods: {
		isMobile() {
			// const isMobile = window.matchMedia("only screen and (max-width: 760px)")
			// console.log( isMobile.matches )
			if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
				return true;
			} else {
				return false;
			}
		},
		
		getlogo() {
			
			let that = this
			that.LocationData.host		= window.location.host
			that.LocationData.hostname  = window.location.hostname
			that.LocationData.origin 	= window.location.origin
			let Location = that.LocationData
			// console.log(location)
			axios.post( '/auth/getlogo', Location ).then(resp => {
				// console.log(resp.data.logo)
				// <link rel="icon" href="/img/ohmylead-favicon.png" type="image/png" sizes="16x16" />
				
				that.$store.state.WhiteLabel  = resp.data.whitelabel
				that.$store.state.IsAdmin	  = resp.data.admin
				that.$store.state.CompanyName = resp.data.company_name
				
				let favicon = resp.data.favicon != null ? resp.data.favicon : ''
				if( resp.data.whitelabel ) {
					that.$store.state.DefaultLogo = resp.data.logo
				} else {
					favicon = 'https://console.ohmylead.com/img/ohmylead-favicon.png'
					that.$store.state.DefaultLogo	= 'https://console.ohmylead.com/img/svg/logo.svg'
				}
				
				if( favicon != '' ) {
					var link 	= document.querySelector("link[rel*='icon']") || document.createElement('link');
					link.type 	= 'image/png';
					link.rel 	= 'icon';
					link.href 	= favicon // '/img/ohmylead-favicon.png';
					link.sizes 	= '16x16';
					document.getElementsByTagName('head')[0].appendChild(link);
				}
				
				let title      = resp.data.company_name != '' ? resp.data.company_name +  ' | ' : ''
				document.title = title + document.title || '';

				if(this.isMobile()){
					console.log("whitelabel",resp.data.whitelabel)
					if(resp.data.whitelabel){
						window.location = resp.data.url_mobile
						
					}else{
						if(window.location.href.indexOf("https://console.ohmylead.com/demo") !== -1){
							console.log(resp.data.whitelabel)
						}else{
							window.location = 'https://console.ohmylead.com/demo'
						}
						
					}
				}
				
			}).catch(err => {
				console.log(err)
				that.DefaultLogo = 'img/svg/logo.svg'
			})
		},
		
		notifyMe(message, variant) {
			this.$notify({
				group	: 'foo',
				// title: 'Important message',
				text	: message,
				type	: variant,
				// duration: 500000,
				// speed	: 1000
			});
		},
		
		
		
		
	},
	filters: {
			
	},
	mounted() {
		let ClientSelected 	= JSON.parse( localStorage.getItem('oml-client') ) 
		if(ClientSelected){
			this.workspaceTimezone 	= ClientSelected.timezone
		}
		if(window.location.href.indexOf("https://console.ohmylead.com") !== -1){
				this.isWhiteLabel = false
			}else{
				this.isWhiteLabel = true
			}
	},
		
		
	}
export default mixin;
